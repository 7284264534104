import type { ReactElement } from 'react'
import type { RaceLiterval } from '@phalaworld/types'

import React, { Suspense, useState, useRef, useEffect, useMemo } from 'react'
import { Button, Spinner } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Head from 'next/head'
import Link from 'next/link'
import tw, { styled } from 'twin.macro'
import * as R from 'ramda'
import { atom, useAtom, useAtomValue } from 'jotai'

import { CAREER_TYPE_CHAIN_MAP, RACE_TYPE_CHAIN_MAP } from '@/types/nftAttribute'
import AppLayout from '@/providers/AppLayout'
import { trpcQuery } from '@/providers/trpc'
import { imgproxy_thumbnail_url } from '@/features/images/imgproxy'
import trimAddress from '@/features/identity/trimAddress'
import useIsMounted from '@/hooks/useIsMounted'
import { CareerIcon } from '@/components/icons/CareerIcon'
import { SpeciesIcon } from '@/components/icons/SpeciesIcon'
import ConditionallyRender from '@/components/ConditionallyRender'
import DiscordIcon from '../lib/svgs/discord.svg'


function useRandomNftPicks(secs: number) {
  const { data: nftInfo, refetch, isLoading, isRefetching } = trpcQuery.campaigns.randomPicks.useQuery(undefined, {suspense: true})
  const rawImageUrl: string | undefined = R.path(['metadata', 'initialAppearanceURL'], nftInfo)
  const resizedImageUrl = rawImageUrl ? imgproxy_thumbnail_url(rawImageUrl, 1080, 1920, 'png') : ''
  const isRaccoon = R.pathEq(['metadata', 'bodyType'], 'XGene Raccoon', nftInfo)

  const isMounted = useIsMounted()

  // auto play with useEffect
  useEffect(() => {
    if (isMounted) {
      const seed = setInterval(async () => {
        await refetch()
      }, 1_000 * secs)
      return () => {
        clearTimeout(seed)
      }
    }
  }, [isMounted, refetch, secs])

  return useMemo(() => ({
    nftInfo: nftInfo!,
    imageUrl: resizedImageUrl,
    isRaccoon,
    isLoading,
    isRefetching,
    refetch,
  }), [nftInfo, resizedImageUrl, isRaccoon, isLoading, isRefetching, refetch])
}

function NftPicksV2() {
  const { nftInfo, imageUrl, isRaccoon } = useRandomNftPicks(8)
  const accountAddr = trimAddress(R.pathOr('', ['owner', 'AccountId'], nftInfo))
  return (
    <>
      <img
        // onLoad={() => setImgLoading(false)}
        src={imageUrl}
        css={[
          tw`w-auto h-[100vw] absolute right-0 z-0 pointer-events-none transition duration-500 ease-in-out transition-opacity`,
          isRaccoon ? tw`top-[-31.25vh]` : tw`top-0`,
          // isImgLoading ? tw`opacity-0` : tw`opacity-100`,
        ]}
      />
      <img
        src="/static/images/landing/circle-ellipse.svg"
        alt="blur-background"
        tw='fixed top-0 left-0 w-[80vw] h-[80vw] top-[60vh] left-[50vw]'
      />
      <div tw='relative z-10 flex flex-col gap-4'>
        <div tw='text-sm'>
          <h4 tw='text-xl font-medium font-secondary mb-2'>SHELL #{nftInfo.nftId}</h4>
          <p tw='flex flex-row gap-2 items-center text-sm'>
            <SpeciesIcon race={nftInfo.metadata.species} tw='h-8 w-8' />
            {RACE_TYPE_CHAIN_MAP[nftInfo.metadata.species]}
          </p>
          <p tw='flex flex-row gap-2 items-center text-sm'>
            <CareerIcon career={nftInfo.metadata.career} tw='h-8 w-8' />
            {CAREER_TYPE_CHAIN_MAP[nftInfo.metadata.career]}
          </p>
          <p tw='flex flex-row gap-0.5 mt-1'>
            <span>Owners:</span>
            <span>@{accountAddr}</span>
          </p>
        </div>
        <Link href={`/collections/${nftInfo.collectionId}/${nftInfo.nftId}`} passHref tw='text-brand'>
          Click to view Shell details
        </Link>
      </div>
    </>
  )
}

function NftPicks() {
  const { data: nftInfo, refetch, isLoading, isRefetching } = trpcQuery.campaigns.randomPicks.useQuery(undefined, {suspense: true})
  const rawImageUrl: string | undefined = R.path(['metadata', 'initialAppearanceURL'], nftInfo)
  const resizedImageUrl = rawImageUrl ? imgproxy_thumbnail_url(rawImageUrl, 1080, 1920, 'png') : ''
  const isRaccoon = R.pathEq(['metadata', 'bodyType'], 'XGene Raccoon', nftInfo)
  const accountAddr = trimAddress(R.pathOr('', ['owner', 'AccountId'], nftInfo))

  const [isImgLoading, setImgLoading] = useState(false)
  const isMounted = useIsMounted()
  const imgRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    if (isMounted && (!imgRef.current || !imgRef.current.complete)) {
      setImgLoading(true)
    }
    if (imgRef.current && imgRef.current.complete) {
      setImgLoading(false)
    }
  }, [isMounted, setImgLoading, rawImageUrl])

  // auto play with useEffect
  useEffect(() => {
    if (isMounted) {
      const seed = setInterval(async () => {
        await refetch()
        setImgLoading(true)
      }, 1_000 * 8)
      return () => {
        clearTimeout(seed)
      }
    }
  }, [isMounted, refetch, setImgLoading])
  

  if (!nftInfo || !isMounted) {
    return null
  }
  return (
    <>
      {(isImgLoading || isLoading || isRefetching) ? (
        <div tw='fixed top-0 right-0 z-[2] w-[50vw] h-screen flex justify-center items-center'>
          <Spinner color='brand.500' />
        </div>
      ) : null}
      <img
        ref={imgRef}
        onLoad={() => setImgLoading(false)}
        src={resizedImageUrl}
        css={[
          tw`w-auto h-[100vw] absolute right-0 z-0 pointer-events-none transition duration-500 ease-in-out transition-opacity`,
          isRaccoon ? tw`top-[-31.25vh]` : tw`top-0`,
          isImgLoading ? tw`opacity-0` : tw`opacity-100`,
        ]}
      />
      <img
        src="/static/images/landing/circle-ellipse.svg"
        alt="blur-background"
        tw='fixed top-0 left-0 w-[80vw] h-[80vw] top-[60vh] left-[50vw]'
      />
      <div tw='relative z-10 flex flex-col gap-4'>
        <div tw='text-sm'>
          <h4 tw='text-xl font-medium font-secondary mb-2'>SHELL #{nftInfo.nftId}</h4>
          <p tw='flex flex-row gap-2 items-center text-sm'>
            <SpeciesIcon race={nftInfo.metadata.species} tw='h-8 w-8' />
            {RACE_TYPE_CHAIN_MAP[nftInfo.metadata.species]}
          </p>
          <p tw='flex flex-row gap-2 items-center text-sm'>
            <CareerIcon career={nftInfo.metadata.career} tw='h-8 w-8' />
            {CAREER_TYPE_CHAIN_MAP[nftInfo.metadata.career]}
          </p>
          <p tw='flex flex-row gap-0.5 mt-1'>
            <span>Owners:</span>
            <span>@{accountAddr}</span>
          </p>
        </div>
        <Link href={`/collections/${nftInfo.collectionId}/${nftInfo.nftId}`} passHref tw='text-brand'>
          Click to view Shell details
        </Link>
      </div>
    </>
  )
}

//
// launch marketplace
//

function Main({ children }: { children: React.ReactNode }) {
  return (
    <div tw="h-screen w-screen fixed z-30 top-0 left-0 pointer-events-none">
      <div tw='w-full max-w-10xl grid grid-cols-12 gap-5 px-5 xl:mx-0 3xl:mx-auto'>
        <section tw='col-span-10 col-start-2 xl:col-start-2 xl:col-span-10 3xl:col-start-2 3xl:col-span-10 flex justify-between items-center'>
          {children}
        </section>
      </div>
    </div>
  )
}

function MotionBackground() {
  return (
    <div tw="w-full h-[100vh] fixed left-0 top-0 z-0 select-none pointer-events-none">
      <motion.video
        tw="w-screen h-screen object-cover"
        muted
        playsInline
        autoPlay
        loop
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <source
          src={`https://nft-assets.phala.world/landing/countdown.webm`}
          type="video/webm"
        />
        <source
          src={`https://nft-assets.phala.world/landing/countdown.mp4`}
          type="video/mp4"
        />
      </motion.video>
    </div>
  )
}

const StyledCollectionIntro = styled.div`
  ${tw`pl-10`}

  .indicator {
    ${tw`absolute -left-10 top-0 h-full w-6 flex flex-row items-center`}
  }

  h4 {
    ${tw`font-medium mb-2 cursor-pointer relative uppercase font-secondary`}
  }

  .intro {
    ${tw`text-base leading-7 overflow-hidden`}
  }
`

function CurrentCollectionIndicator({ isActive }: { isActive: boolean }) {
  return (
    <svg css={[isActive ? tw`h-6 w-6` : tw`h-4 w-4`]} viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
      <rect x="11.3137" y="0.707107" width="15" height="15" transform="rotate(45 11.3137 0.707107)" stroke="currentColor"/>
      {isActive ? (
        <rect x="11.3137" y="7.31371" width="5.65686" height="5.65686" transform="rotate(45 11.3137 7.31371)" fill="currentColor"/>
      ) : null}
    </svg>
  )
}

const currentCollectionAtom = atom<string>('Overview')

function CollectionIntro({ title, children }: { title: string, children?: React.ReactNode }) {
  const [currentCollection, setCurrentCollection] = useAtom(currentCollectionAtom)
  const collapsed = currentCollection === title
  return (
    <StyledCollectionIntro onClick={() => setCurrentCollection(title)}>
      <motion.h4
        initial={{ fontSize: '1rem', color: '#71717A' }}
        animate={{ fontSize: !collapsed ? '1rem' : '1.5rem', color: !collapsed ? '#71717A' : '#FFFFFF' }}
        transition={{ duration: 0.15 }}
      >
        <div className='indicator'>
          <CurrentCollectionIndicator isActive={currentCollection === title} />
        </div>
        {title}
      </motion.h4>
      <motion.div
        className="intro"
        initial={{ height: 0 }}
        animate={{ height: !collapsed ? '0px' : 'auto' }}
        transition={{ duration: 0.15 }}
      >
        {children}
      </motion.div>
    </StyledCollectionIntro>
  )
}

const ShowOffSlogan = styled.li`
  ${tw`relative leading-none ml-[1.75rem] text-base 2xl:text-lg 4xl:text-xl`}
  
  &:before {
    ${tw`absolute -left-[1.5rem] top-1 w-2 h-2 2xl:top-2 2xl:w-3 2xl:h-3 bg-brand rounded-full`}
    content: '';
  }
`

function CollectionList() {
  const currentCollection = useAtomValue(currentCollectionAtom)
  return (
    <div tw="flex flex-col gap-10 justify-center relative w-full h-screen pointer-events-auto">
      <div tw='flex flex-col gap-2'>
        {currentCollection === 'Overview' ? (
          <div tw="flex flex-col gap-4 3xl:gap-8 relative z-10 w-full">
            <header tw='font-secondary text-white'>
              <h3 tw='text-xl xl:text-[1.75rem] xl:mb-4'>Become a survivor in the</h3>
              <h1 tw='text-2xl xl:text-[2.5rem] font-medium uppercase leading-10'>immersive world</h1>
            </header>
            <main tw='flex flex-col gap-6'>
              <h4 tw='font-secondary font-semibold text-xl text-brand uppercase'>The Gen 0 Shells have awoken</h4>
              <ul tw='flex flex-col gap-2.5'>
                <ShowOffSlogan>Soulbound</ShowOffSlogan>
                <ShowOffSlogan>Dynamic</ShowOffSlogan>
                <ShowOffSlogan>Hyper-customizable</ShowOffSlogan>
              </ul>
            </main>
          </div>
        ) : (
          <div tw='flex flex-col gap-4'>
            <CollectionIntro title="Overview">
            </CollectionIntro>
            <CollectionIntro title="Spirit NFT">
              <p>In PhalaWorld, Spirit is a unique Soulbound NFT reflecting a survivor's engagement with the crypto world. As users interact more, their free-minted Spirit levels up, showcasing their growing involvement.</p>
            </CollectionIntro>
            <CollectionIntro title="Origin Shell NFT">
              <p>Origin Shell is the juvenile form in PhalaWorld. All survivors start as these creatures, needing nutrients to grow and power their equipment. With determination, they evolve into the formidable Shell, ready to face the post-apocalyptic world.</p>
            </CollectionIntro>
            <CollectionIntro title="Shell NFT">
              <p>In PhalaWorld, the Shell serves as the survivor's avatar, emerging from the Origin Shell. Customized by each survivor within the hangar, Shells possess dynamic, compatible, and highly customizable properties. The individual NFT components can be separated and freely exchanged in the marketplace. There are 4 main species in Shell.</p>
            </CollectionIntro>
          </div>
        )}
      </div>
      <footer tw="flex flex-row gap-2 relative">
        {(currentCollection === 'Overview' || currentCollection === 'Spirit NFT') ? (
          <Link href="/claim" passHref legacyBehavior>
            <Button size="lg" as="a">Claim Spirit</Button>
          </Link>
        ) : null}
        {(currentCollection !== 'Spirit NFT') ? (
          <Link href="/marketplace" passHref legacyBehavior>
            <Button size="lg" as="a">Marketplace</Button>
          </Link>
        ) : null}
        <Link href="https://discord.gg/s45eDZ8KQf" passHref legacyBehavior>
          <Button as='a' size='lg' target='_blank' rel='noopener noreferrer' bg='#5865F2' textColor='white'>
            <DiscordIcon tw='text-white h-6 w-6' />
          </Button>
        </Link>
      </footer>
    </div>
  )
}

function ShellNftAvatar() {
  const { imageUrl, isRaccoon } = useRandomNftPicks(8)
  return (
    <img
      src={imageUrl}
      alt="Shell NFT" 
      css={[
        tw`w-auto h-full absolute right-0 z-0 pointer-events-none transition duration-500 ease-in-out transition-opacity`,
        isRaccoon ? tw`top-[-15%]` : tw`top-0`
      ]}
    />
  )
}

function CollectionAvatarTriangle() {
  const [currentCollection, setCurrentCollection] = useAtom(currentCollectionAtom)
  return (
    <motion.div
      className='overview'
      css={[
        tw`w-screen flex flex-col justify-center items-end relative z-30`, 'height: calc(100vh - 100px); margin-top: 100px;',
        currentCollection === 'Overview' ? tw`pointer-events-auto z-30` : tw`pointer-events-none z-0`,
      ]}
      initial={{ opacity: 0 }}
      animate={{ opacity: currentCollection === 'Overview' ? 1 : 0 }}
      transition={{ duration: 0.15 }}
      exit={{ opacity: 0 }}
    >
      <div css={['aspect-ratio: 470/408;', tw`w-full absolute`]}>
        {/* Triangle background image, and we puts shell, spirit, origin shell on each vertex */}
        <img src="/static/landing/triangle.png" tw='w-full z-10 opacity-50' />
        {/* The Shell NFT */}
        <div
          css={[
            tw`absolute z-[10] w-full h-[90%] top-[-10%] left-[-27%] cursor-pointer transition-all transform-gpu`,
            tw`hover:h-[100%] hover:top-[-15%] hover:left-[-25%] hover:brightness-150`
          ]}
          onClick={() => setCurrentCollection('Shell NFT')}
        >
          <ConditionallyRender client>
            <Suspense fallback={null}>
              <ShellNftAvatar />
            </Suspense>
          </ConditionallyRender>
        </div>
        {/* The Spirit NFT */}
        <div
          css={[
            tw`absolute z-10 w-auto h-[70%] bottom-[-17%] left-[-7%] cursor-pointer transition-all transform-gpu opacity-75`,
            tw`hover:h-[80%] hover:opacity-100 hover:bottom-[-22%] hover:left-[-11%]`,
            'aspect-ratio: 1/1;'
          ]}
          onClick={() => setCurrentCollection('Spirit NFT')}
        >
          <img
            src="/static/images/indexPage/Spirit/step01_img_spirits_hover.png"
            tw="object-cover max-w-full max-h-full"
          />
        </div>
        {/* The Origin Shell NFT */}
        <div
          css={[
            tw`absolute z-20 w-auto h-[35%] bottom-0 right-[7%] cursor-pointer transition-all transform-gpu opacity-75`,
            tw`hover:opacity-100 hover:h-[40%] hover:bottom-[-2%] hover:right-[5%]`,
            // 'bottom: 0; right: 7%;'
          ]}
          onClick={() => setCurrentCollection('Origin Shell NFT')}
        >
          <img
            src="/static/images/mintPage/born-icon/born/PrimeEdition/X-Gene.png"
            tw="object-cover max-w-full max-h-full"
          />
        </div>
      </div>
    </motion.div>
  )
}

function ShellNftPicks() {
  const currentCollection = useAtomValue(currentCollectionAtom)
  return (
    <div
      css={[
        tw`h-screen w-screen fixed top-0 left-0`,
        currentCollection === 'Shell NFT' ? tw`pointer-events-auto z-20` : tw`pointer-events-none z-0`
      ]}
    >
      <div tw='w-full max-w-10xl grid grid-cols-12 gap-5 px-5 xl:mx-0 3xl:mx-auto'>
        <section tw='col-span-10 col-start-2 xl:col-start-2 xl:col-span-10 3xl:col-start-2 3xl:col-span-10 flex justify-between items-center'>
          <motion.div
            tw='h-screen w-screen flex flex-col justify-end items-end py-[10vh] opacity-0'
            initial={{ opacity: 0 }}
            animate={{ opacity: currentCollection === 'Shell NFT' ? 1 : 0 }}
            transition={{ duration: 0.15 }}
            exit={{ opacity: 1 }}
          >
            <ConditionallyRender client>
              <Suspense fallback={null}>
                <NftPicksV2 />
              </Suspense>
            </ConditionallyRender>
          </motion.div>
        </section>
      </div>
    </div>
  )
}

function SpiritNftPreview() {
  const currentCollection = useAtomValue(currentCollectionAtom)
  return (
    <motion.video
      muted
      playsInline
      autoPlay
      loop
      initial={{ opacity: 0 }}
      animate={{ opacity: currentCollection === 'Spirit NFT' ? 1 : 0 }}
      transition={{ duration: 0.15 }}
      exit={{ opacity: 1 }}
      css={[
        tw`w-1/2 z-40 absolute right-0 pointer-events-none`,
        'top: calc((100vh - 50%) / 2 - 100px);',
        'mix-blend-mode: screen;',
        'aspect-ratio: 470/408;',
      ]}
    >
      <source
        src="https://nft-assets.phala.world/artifacts/spirit.mp4"
        type="video/mp4"
      />
    </motion.video>
  )
}

const currentRaceAtom = atom<RaceLiterval>('Cyborg')

const OriginShellRaceButton = styled.button<{ isActive?: boolean }>`
  ${tw`text-white flex flex-col justify-center items-center`}

  > svg {
    ${tw`transition-all duration-500`}
    ${({ isActive }) => !isActive ? 'opacity: 20%;' : ''}
  }

  > span {
    ${tw`transition-all duration-500`}
    ${({ isActive }) => !isActive ? 'opacity: 0;' : ''}
  }

  &:hover {
    > svg {
      ${tw`opacity-100`}
    }
    > span {
      ${tw`opacity-100`}
    }
  }
`

function OriginShellNftPreview() {
  const [isAnimateCompleted, setAnimateCompleted] = useState(false)
  const currentCollection = useAtomValue(currentCollectionAtom)
  const [currentRace, setCurrentRace] = useAtom(currentRaceAtom)
  const { isCyborg, isAISpectre, isXGene, isPandroid } = useMemo(() => ({
    isCyborg: currentRace === 'Cyborg',
    isAISpectre: currentRace === 'AISpectre',
    isXGene: currentRace === 'XGene',
    isPandroid: currentRace === 'Pandroid',
  }), [currentRace])
  return (
    <motion.div
      css={[
        tw`h-screen w-screen flex flex-col justify-end items-end absolute overflow-hidden top-0`,
        currentCollection === 'Origin Shell NFT' ? tw`pointer-events-auto z-20` : tw`pointer-events-none z-0`,
      ]}
      initial={{ opacity: 0 }}
      animate={{ opacity: currentCollection === 'Origin Shell NFT' ? 1 : 0 }}
      transition={{ duration: 0.15 }}
      exit={{ opacity: 0 }}
    >
      <div css={['aspect-ratio: 470/408;', tw`flex justify-center items-center h-[100vh] relative`]}>
        <div tw='absolute top-[70%] bottom-0 flex flex-row gap-4'>
          <OriginShellRaceButton isActive={currentRace === 'Cyborg'} onClick={() => setCurrentRace('Cyborg')}>
            <SpeciesIcon race='Cyborg' tw='w-14 h-14' />
            <span>Cyborg</span>
          </OriginShellRaceButton>
          <OriginShellRaceButton isActive={currentRace === 'AISpectre'} onClick={() => setCurrentRace('AISpectre')}>
            <SpeciesIcon race='AI Spectre' tw='w-14 h-14' />
            <span>AI Spectre</span>
          </OriginShellRaceButton>
          <OriginShellRaceButton isActive={currentRace === 'XGene'} onClick={() => setCurrentRace('XGene')}>
            <SpeciesIcon race='X-Gene' tw='w-14 h-14' />
            <span>X-Gene</span>
          </OriginShellRaceButton>
          <OriginShellRaceButton isActive={currentRace === 'Pandroid'} onClick={() => setCurrentRace('Pandroid')}>
            <SpeciesIcon race='Pandroid' tw='w-14 h-14' />
            <span>Pandroid</span>
          </OriginShellRaceButton>
        </div>
        <div tw='max-h-full w-[33vw] overflow-hidden pointer-events-none'>
          <motion.div
            tw='max-h-full w-[132vw] pb-[5%] flex flex-row'
            initial={{ translateX: 0 }}
            animate={{
              translateX: isCyborg ? 0 : isAISpectre ? '-33vw' : isXGene ? '-66vw' : '-99vw',
            }}
            transition={{ duration: 0.3 }}
            exit={{ translateX: 0 }}
            onAnimationStart={() => setAnimateCompleted(false)}
            onAnimationComplete={() => setAnimateCompleted(true)}
          >
            <motion.div
              tw="w-[33vw] h-full flex justify-center"
              initial={{ opacity: 0, filter: 'brightness(0)' }}
              animate={{ opacity: (isCyborg && isAnimateCompleted) ? 1 : 0, filter: isCyborg ? 'brightness(1)' : 'brightness(0)' }}
              transition={{ duration: 0.15 }}
              exit={{ opacity: 0, filter: 'brightness(0)' }}
            >
              <motion.img
                src="/static/landing/origin-shell-cyborg.png"
                tw="object-cover max-w-full max-h-full"
              />
            </motion.div>
            <motion.div
              tw="w-[33vw] h-full flex justify-center"
              initial={{ opacity: 0, filter: 'brightness(0)' }}
              animate={{ opacity: (isAISpectre && isAnimateCompleted) ? 1 : 0, filter: isAISpectre ? 'brightness(1)' : 'brightness(0)' }}
              transition={{ duration: 0.15 }}
              exit={{ opacity: 0, filter: 'brightness(0)' }}
            >
              <motion.img
                src="/static/landing/origin-shell-ai-spectre.png"
                tw="object-cover max-w-full max-h-full"
              />
            </motion.div>
            <motion.div
              tw="w-[33vw] h-full flex justify-center"
              initial={{ opacity: 0, filter: 'brightness(0)' }}
              animate={{ opacity: (isXGene && isAnimateCompleted) ? 1 : 0, filter: isXGene ? 'brightness(1)' : 'brightness(0)' }}
              transition={{ duration: 0.15 }}
              exit={{ opacity: 0, filter: 'brightness(0)' }}
            >
              <motion.img
                src="/static/landing/origin-shell-x-gene.png"
                tw="object-cover max-w-full max-h-full"
              />
            </motion.div>
            <motion.div
              tw="w-[33vw] h-full flex justify-center"
              initial={{ opacity: 0, filter: 'brightness(0)' }}
              animate={{ opacity: (isPandroid && isAnimateCompleted) ? 1 : 0, filter: isPandroid ? 'brightness(1)' : 'brightness(0)' }}
              transition={{ duration: 0.15 }}
              exit={{ opacity: 0, filter: 'brightness(0)' }}
            >
              <motion.img
                src="/static/landing/origin-shell-pandroid.png"
                tw="object-cover max-w-full max-h-full"
              />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
}

export default function IndexPage() {
  return (
    <>
      <Head>
        <title>PhalaWorld</title>
        <link rel="dns-prefetch" href="https://nft-assets.phala.world" />
      </Head>
      <ShellNftPicks />
      <OriginShellNftPreview />
      <SpiritNftPreview />
      <Main>
        <CollectionList />
        <CollectionAvatarTriangle />
      </Main>
      <MotionBackground />
    </>
  )
}

IndexPage.getLayout = (page: ReactElement) => {
  return (
    <AppLayout>
      {page}
    </AppLayout>
  )
}
