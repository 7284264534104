export type IMAGE_FORMAT_LITERVAL = 'png' | 'jpeg' | 'jpg' | 'webp'

export function imgproxy_thumbnail_url(url: string, width: number, height: number, format?: IMAGE_FORMAT_LITERVAL) {
  const imgproxy = process.env.NEXT_PUBLIC_IMGPROXY_URL
  if (!imgproxy) {
    return url
  }
  const args = [`rs:fit:${width}:${height}:0:0`]
  if (format) {
    args.push(`f:${format}`)
  }
  return `${imgproxy}/insecure/cb:0118/${args.join('/')}/plain/${url}.${format || 'webp'}`
}